import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f335cc06"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "cookies-base"
}
const _hoisted_2 = { id: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.cookiesAccepted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createTextVNode(" We use cookies on our website to enhance your browsing experience. Additionally, two third-party iframes incorporated on our site may also use cookies to analyze website traffic. "),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.accepted && _ctx.accepted(...args)))
          }, "Accept"),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.declined && _ctx.declined(...args)))
          }, "Decline")
        ])
      ]))
    : _createCommentVNode("", true)
}